'use strict';

/* globals Notification */
import { PUBLIC_VAPID_KEY } from '../../sharedsrc/constants';
import { arrayBufferToBase64Url, uint8ArrayFromBase64Url } from '../../sharedsrc/utilities';
import store from '../store';
import { setNotificationToken, /* calculateTicketCounters */ } from './wsMsg';

export let token = '', messaging = {
  getToken: () => Promise.resolve(token || false),
  worker: undefined, // Deferred!
};
// IDs of divs that display Instance ID token UI or request permission UI.
// const tokenDivId = 'token_div';
// const permissionDivId = 'permission_div';
const serviceWorkerVersion = process.env.PACKAGE_VERSION; 

export function init () {
  const registerPromise = messaging.worker = ('serviceWorker' in navigator
    ? navigator.serviceWorker.register(`/firebase-messaging-sw.js?v=${serviceWorkerVersion}`, { scope: '/firebase-cloud-messaging-push-scope' })
    : Promise.reject(new Error('Messaging serviceWorker not supported!')));

  registerPromise
    .then(register => register.pushManager.getSubscription()
      .then(subscription => {
        // https://stackoverflow.com/questions/45994933/changing-application-server-key-in-push-manager-subscription
        if (!subscription) return { register, subscription: null };
        if (subscription.options && subscription.options.applicationServerKey) {
          const applicationServerKey = arrayBufferToBase64Url(subscription.options.applicationServerKey);
          if (PUBLIC_VAPID_KEY === applicationServerKey) return { register, subscription };
        }
        return subscription.unsubscribe().then(() => ({ register, subscription: null }));
      })
    ).then(({ register, subscription }) => subscription || register.pushManager.subscribe({ // register push
      userVisibleOnly: true,
      applicationServerKey: uint8ArrayFromBase64Url(PUBLIC_VAPID_KEY), // public vapid key
    })).then(subscription => {
      const { endpoint } = subscription;
      const auth = arrayBufferToBase64Url(subscription.getKey('auth'));
      const p256dh = arrayBufferToBase64Url(subscription.getKey('p256dh'));
      token = JSON.stringify({ endpoint, keys: { auth, p256dh } });
      return resetUI();
    }).catch(err => {
      return console.warn('Messaging serviceWorker Err:', err.message);
    });

  /*
  // Add the public key generated from the console here
  messaging.usePublicVapidKey(PUBLIC_VAPID_KEY);

  // [START refresh_token]
  // Callback fired if Instance ID token is updated.
  messaging.onTokenRefresh(() => {
    messaging.getToken().then((refreshedToken) => {
      // console.log('Token refreshed.');
      // Indicate that the new Instance ID token has not yet been sent to the
      // app server.
      setTokenSentToServer(false);
      // Send Instance ID token to app server.
      sendTokenToServer(refreshedToken);
      // [START_EXCLUDE]
      // Display new Instance ID token and clear UI of all previous messages.
      resetUI();
      // [END_EXCLUDE]
    }).catch((err) => {
      // console.log('Unable to retrieve refreshed token ', err);
      showToken('Unable to retrieve refreshed token ', err);
    });
  });
  // [END refresh_token]

  // [START receive_message]
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.setBackgroundMessageHandler` handler.
  messaging.onMessage((payload) => {
    // console.log('Message received. ', payload);
    // [START_EXCLUDE]
    // Update the UI to include the received message.
    appendMessage(payload);
    calculateTicketCounters(store.state.ownUUID);
    // [END_EXCLUDE]
  });
  // [END receive_message]
  */

  resetUI();
  return messaging;
}

// document.addEventListener('DOMContentLoaded', init);

function resetUI () {
  clearMessages();
  // showToken('loading...');
  // [START get_token]
  // Get Instance ID token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  messaging.getToken().then((currentToken) => {
    if (currentToken) {
      sendTokenToServer(currentToken);
      updateUIForPushEnabled(currentToken);
    } else {
      // Show permission request.
      // console.log('No Instance ID token available. Request permission to generate one.');
      // Show permission UI.
      updateUIForPushPermissionRequired();
      setTokenSentToServer(false);
    }
  }).catch((err) => {
    console.warn('An error occurred while retrieving messaging token:', err);
    setTokenSentToServer(false);
    store.state.notificationsShouldAsk = (Notification.permission === 'default');
  });
  // [END get_token]
}

function showToken (currentToken) {
  // Show token in console and UI.
  // const tokenElement = document.querySelector('#token');
  // tokenElement.textContent = currentToken;
  // console.log('Token:', currentToken);
}

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
function sendTokenToServer (currentToken) {
  // Just always send for now ~F
  // if (!isTokenSentToServer()) {
  // console.log('Sending token to server...');
  setNotificationToken(currentToken, currentToken);
  setTokenSentToServer(true);
  // } else {
  //   console.log('Token already sent to server so won\'t send it again unless it changes');
  // }
}

function isTokenSentToServer () { // eslint-disable-line no-unused-vars
  return window.localStorage.getItem('sentToServer') === 'true';
}

function setTokenSentToServer (sent) {
  window.localStorage.setItem('sentToServer', sent ? 'true' : '0');
}

function showHideDiv (divId, show) { // eslint-disable-line no-unused-vars
  // const div = document.querySelector('#' + divId);
  // if (show) {
  //   div.style = 'display: visible';
  // } else {
  //   div.style = 'display: none';
  // }
}

export function requestPermission () {
  // console.log('Requesting permission...');
  // [START request_permission]
  return Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      // console.log('Notification permission granted.');
      // TODO(developer): Retrieve an Instance ID token for use with FCM.
      // [START_EXCLUDE]
      // In many cases once an app has been granted notification permission,
      // it should update its UI reflecting this.
      resetUI();
      // [END_EXCLUDE]
    } else {
      // console.log('Unable to get permission to notify.');
      store.state.notificationsShouldAsk = false;
    }
  });
  // [END request_permission]
}

/*
export function deleteToken () {
  // Delete Instance ID token.
  // [START delete_token]
  messaging.getToken().then((currentToken) => {
    messaging.deleteToken(currentToken).then(() => {
      // console.log('Token deleted.');
      setTokenSentToServer(false);
      // [START_EXCLUDE]
      // Once token is deleted update UI.
      resetUI();
      // [END_EXCLUDE]
    }).catch((err) => {
      console.warn('Unable to delete token. ', err);
    });
    // [END delete_token]
  }).catch((err) => {
    // console.log('Error retrieving Instance ID token. ', err);
    showToken('Error retrieving Instance ID token. ', err);
  });
}

// Add a message to the messages element.
function appendMessage (payload) {
  // const messagesElement = document.querySelector('#messages');
  // const dataHeaderELement = document.createElement('h5');
  // const dataElement = document.createElement('pre');
  // dataElement.style = 'overflow-x:hidden;';
  // dataHeaderELement.textContent = 'Received message:';
  // dataElement.textContent = JSON.stringify(payload, null, 2);
  // messagesElement.appendChild(dataHeaderELement);
  // messagesElement.appendChild(dataElement);
  // console.log('Received message:', JSON.stringify(payload, null, 2));
}
*/

// Clear the messages element of all children.
function clearMessages () {
  // const messagesElement = document.querySelector('#messages');
  // while (messagesElement.hasChildNodes()) {
  //  messagesElement.removeChild(messagesElement.lastChild);
  // }
}

function updateUIForPushEnabled (currentToken) {
  // showHideDiv(tokenDivId, true);
  // showHideDiv(permissionDivId, false);
  showToken(currentToken);
  store.state.notificationsShouldAsk = false;
  store.state.notificationsSubscribed = true;
}

function updateUIForPushPermissionRequired () {
  // showHideDiv(tokenDivId, false);
  // showHideDiv(permissionDivId, true);
  store.state.notificationsShouldAsk = (Notification.permission === 'default');
  store.state.notificationsSubscribed = false;
}
